// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getProducers = createAsyncThunk('appEcommerce/getProducers', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/customer/producers`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getProducts = createAsyncThunk('appEcommerce/getProducts', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/customer/products`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getOrders = createAsyncThunk('appEcommerce/getOrders', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/customer/client_orders`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getCartItems = createAsyncThunk('customer/getCartProducts', async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/customer/cart_products`
  const response = await axios.get(endpoint)
  return response.data
})

export const getOrder = createAsyncThunk('customer/getOrder', async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/customer/client_orders/currentOrder`
  const response = await axios.get(endpoint)
  return response.data
})

export const getGrapeTypes = createAsyncThunk('customer/getGrapeTypes', async params => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}/customer/products/grape_types`
    const response = await axios.get(endpoint, { params })
    return { params, data: response.data }
  } catch (err) {
    return { error: err }
  }
})

export const getWineTypes = createAsyncThunk('customer/getWineTypes', async params => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}/customer/products/wine_types`
    const response = await axios.get(endpoint, { params })
    return { params, data: response.data }
  } catch (err) {
    return { error: err }
  }
})

export const getCountries = createAsyncThunk('customer/getCountries', async params => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}/customer/producers/countries`
    const response = await axios.get(endpoint, { params })
    return { params, data: response.data }
  } catch (err) {
    return { error: err }
  }
})

export const getVolumes = createAsyncThunk('customer/getVolumes', async params => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}/customer/products/volumes`
    const response = await axios.get(endpoint, { params })
    return { params, data: response.data }
  } catch (err) {
    return { error: err }
  }
})


export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    cart: {},
    orders: [],
    order: {},
    products: [],
    productsLoaded: false,
    productHighPrice: 0.0,
    productLowPrice: 0.0,
    productFilters: {
      sortBy: 'nameAsc'
    },
    grape_types: [],
    grapeTypesLoaded: false,
    wine_types: [],
    wineTypesLoaded: false,
    volumes: [],
    volumesLoaded: false,
    countries: [],
    countriesLoaded: false,
    producers: [],
    producersLoaded: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProducers.fulfilled, (state, action) => {
        state.producers = action.payload.data.producers
        state.producersLoaded = true
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.data.products
        state.productsLoaded = true
        state.productHighPrice = action.payload.data.high_price
        state.productLowPrice = action.payload.data.low_price
        state.productFilters.sortBy = action.payload.params.sortBy
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.orders = action.payload.data
      })
      .addCase(getGrapeTypes.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.grape_types = action.payload.data.grape_types
          state.grapeTypesLoaded = true
        }
      })
      .addCase(getWineTypes.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.wine_types = action.payload.data.wine_types
          state.wineTypesLoaded = true
        }
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.countries = action.payload.data.countries
          state.countriesLoaded = true
        }
      })
      .addCase(getVolumes.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.volumes = action.payload.data.volumes
          state.volumesLoaded = true
        }
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.order = action.payload
      })
  }
})

export default appEcommerceSlice.reducer
