// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from './authentication'
import ecommerce from '@src/views/customer/store'
import admin from '@src/views/admin/store'

const rootReducer = { auth, navbar, layout, ecommerce, admin }

export default rootReducer
