// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getUsers = createAsyncThunk('admin/getUsers', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/users`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getClientRegions = createAsyncThunk('admin/getClientRegions', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/client_regions`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getClientProvinces = createAsyncThunk('admin/getClientProvinces', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/client_provinces`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getClients = createAsyncThunk('admin/getClients', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/clients`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getClientOrders = createAsyncThunk('admin/getClientOrders', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/client_orders`
  const response = await axios.get(endpoint, { params })
  return { data: response.data }
})

export const getClientReferrals = createAsyncThunk('admin/getClientReferrals', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/client_referrals`
  const response = await axios.get(endpoint, { params })
  return { data: response.data }
})

export const getProducerOrders = createAsyncThunk('admin/getProducerOrders', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/producer_orders`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getProducts = createAsyncThunk(
  'admin/getProducts',
  async params => {
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/admin/products`
      const response = await axios.get(endpoint, { params })
      return { params, data: response.data }
    } catch (err) {
      return { error: err }
    }
})

export const getRootProducts = createAsyncThunk(
  'admin/getRootProducts',
  async params => {
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/admin/root_products`
      const response = await axios.get(endpoint, { params })
      return { params, data: response.data }
    } catch (err) {
      return { error: err }
    }
})

export const getGrapeTypes = createAsyncThunk(
  'admin/getGrapeTypes',
  async params => {
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/admin/products/grape_types`
      const response = await axios.get(endpoint, { params })
      return { params, data: response.data }
    } catch (err) {
      return { error: err }
    }
})

export const getProducers = createAsyncThunk(
  'admin/getProducers',
  async params => {
    try {
      const endpoint = `${process.env.REACT_APP_API_URL}/admin/producers`
      const response = await axios.get(endpoint, { params })
      return { params, data: response.data }
    } catch (err) {
      return { error: err }
    }
  }
)

export const getTastings = createAsyncThunk('admin/getTastings', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/tastings`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getReservations = createAsyncThunk('admin/getReservations', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/reservations`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})


export const getReddivities = createAsyncThunk('admin/getReddivities', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/reddivities`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getProductStats = createAsyncThunk('admin/getProductStats', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/product_stats`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const getStats = createAsyncThunk('admin/getStats', async params => {
  const endpoint = `${process.env.REACT_APP_API_URL}/admin/stats`
  const response = await axios.get(endpoint, { params })
  return { params, data: response.data }
})

export const setActiveClient = createAsyncThunk('admin/setActiveClient', async clientSlug => {
  return clientSlug
})

export const setClientOrdersFilters = createAsyncThunk('admin/setClientOrdersFilters', async params => {
  return params
})

export const setProducerOrdersFilters = createAsyncThunk('admin/setProducerOrdersFilters', async params => {
  return params
})

export const mainResourceGetters = {
  clients: getClients,
  client_orders: getClientOrders,
  client_referrals: getClientReferrals,
  producer_orders: getProducerOrders,
  producers: getProducers,
  products: getProducts,
  grape_types: getGrapeTypes,
  tastings: getTastings,
  reservations: getReservations,
  users: getUsers
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    users: [],
    client_regions: [],
    client_provinces: [],
    clients: [],
    client_orders: [],
    client_orders_filters: {
      invoiced: 'all',
      shipped: 'all',
      paid: 'all',
      reminderEmailSent: 'all',
      clientNotified: 'all',
      storageNotified: 'all',
      delivered: 'all',
      prepaid: 'all',
      adminOnly: 'all',
      month: 'all',
      year: new Date().getFullYear()
    },
    client_referrals: [],
    producer_products: [],
    producer_orders: [],
    producer_orders_filters: {
      month: 'all',
      year: new Date().getFullYear()
    },
    
    products: [],
    stock_value: 0.0,
    storage_quantity: 0,
    available_quantity: 0,
    reserved_quantity: 0,
    root_products: [],
    grape_types: [],
    producers: [],
    tastings: [],
    reservations: [],
    reddivities: [],
    product_stats: [],
    stats: {},
    activeClient: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data.users
      })
      .addCase(getClientRegions.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.client_regions = action.payload.data.regions
          state.client_provinces = action.payload.data.provinces
        }
      })
      .addCase(getClientProvinces.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.client_provinces = action.payload.data
        }
      })
      .addCase(getClients.fulfilled, (state, action) => {
        if (action.payload.data.clients && !action.payload.data.local) {
          state.clients = action.payload.data.clients
        }
      })
      .addCase(getClientOrders.fulfilled, (state, action) => {
        state.client_orders = action.payload.data.client_orders
      })
      .addCase(getClientReferrals.fulfilled, (state, action) => {
        state.client_referrals = action.payload.data.client_referrals
      })
      .addCase(getProducerOrders.fulfilled, (state, action) => {
        state.producer_orders = action.payload.data.producer_orders
      })
      .addCase(setProducerOrdersFilters.fulfilled, (state, action) => {
        state.producer_order_filters = action.payload.params
      })
  
      .addCase(getProducts.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else if (!action.payload.data.local) {
          if (action.payload.data.products) {
            state.products = action.payload.data.products
            state.stock_value = action.payload.data.stock_value
            state.storage_quantity = action.payload.data.storage_quantity
            state.available_quantity = action.payload.data.available_quantity
            state.reserved_quantity = action.payload.data.reserved_quantity
          }
        }
      })
      .addCase(getRootProducts.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else if (!action.payload.data.local) {
          if (action.payload.data.root_products) {
            state.root_products = action.payload.data.root_products
          }
        }
      })
      .addCase(getGrapeTypes.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.grape_types = action.payload.data.grape_types
        }
      })
      .addCase(getProducers.fulfilled, (state, action) => {
        if (action.payload.error) {
          // console.log(action.payload.error)
          //  TODO
        } else {
          state.producers = action.payload.data.producers
        }
      })
      .addCase(getTastings.fulfilled, (state, action) => {
        state.tastings = action.payload.data.tastings
      })
      .addCase(getReservations.fulfilled, (state, action) => {
        state.reservations = action.payload.data.reservations
      })
      .addCase(getReddivities.fulfilled, (state, action) => {
        state.reddivities = action.payload.data.reddivities
      })
      .addCase(getProductStats.fulfilled, (state, action) => {
        state.product_stats = action.payload.data.product_stats
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.stats = action.payload.data
      })
      .addCase(setClientOrdersFilters.fulfilled, (state, action) => {
        state.client_order_filters = action.payload.params
      })
      .addCase(setActiveClient.fulfilled, (state, action) => {
        state.activeClient = action.payload.clientSlug
      })
  }
})

export default adminSlice.reducer
